<template>
  <div>
    <div class="mine-info-view">
      <img class="info-head-portrait" :src="headImgUrl" v-on:click="loginAndRegister">
      <input id="inputElement" hidden name="file" type="file" accept="image/png, image/gif, image/jpeg"  />
      <div class="info-user-name" v-on:click="loginAndRegister">{{userName}}</div>
      <div class="info-user-level">{{userInfo.level==0?'普通':'会员'}}用户</div>

      <div class="user-income">
        <nut-row>
          <nut-col :span="8">
            <div class="flex-content">
              <p>总收入({{allBalance.toFixed(2)}}元)</p>
            </div>
          </nut-col>
          <nut-col :span="8">
            <div class="flex-content flex-content-light">
              <p>已结算({{settledBalance.toFixed(2)}}元)</p>
            </div>
          </nut-col>
          <nut-col :span="8">
            <div class="flex-content">
              <p>余额({{balance.toFixed(2)}}元)</p>
            </div>
          </nut-col>
        </nut-row>
      </div>
    </div>

   <div class="mine-list">
     <div class="mine-list-line" v-on:click="herf('/shareLogin')">
       <svg class="icon" aria-hidden="true">
         <use xlink:href="#icon-fenxiang1"></use>
       </svg>
       <span>邀请注册</span>
     </div>
     <div v-if="!openid" class="mine-list-line" v-on:click="bindwx()">
       <svg t="1635479619315" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4847" width="200" height="200"><path d="M490.2 361.3c22.2 0 36.6-14.9 36.6-36.6 0-21.7-14.9-36.6-36.6-36.6-21.2 0-43.8 14-43.8 36.6 0 22.1 22.7 36.6 43.8 36.6z m0 0" p-id="4848" fill="#1afa29"></path><path d="M330.8 324.2c0-21.7-14.9-36.6-36.6-36.6-21.2 0-43.8 14-43.8 36.6s22.6 36.6 43.8 36.6c22.2 0.5 36.6-14.4 36.6-36.6z m0 0" p-id="4849" fill="#1afa29"></path><path d="M910.2 583.3c0-53.9-24.1-106-67.4-146.4-42.9-40-100.2-64.5-160.9-69.8v-2.9c-25.5-118.5-152.7-208-296.2-208C223 156.1 91 269.3 91 408.5c0 74.2 37.6 141.6 109.3 194.1l-24.6 74.2c-1.9 5.8 0 11.6 4.3 15.4 4.3 3.9 11.1 4.3 15.9 1.9l93.4-46.7 11.1 2.4h0.5c28.9 5.8 53.9 11.1 84.8 11.1 5.8 0 27.9-1.9 35.2-5.3 35.6 86.7 131 145 237.9 145 27 0 54.4-6.3 81.9-13l71.8 39c1.9 1 4.3 1.9 6.7 1.9 3.4 0 6.7-1 9.1-3.4 4.8-3.9 6.3-9.6 4.8-15.4l-18.3-60.2c61.2-48.7 95.4-107.9 95.4-166.2zM782.6 725.9c-4.8 3.4-6.7 9.6-4.8 14.9l10.1 34.2-43.3-23.6c-1.9-1-4.3-1.9-6.7-1.9-1 0-2.4 0-3.4 0.5-25 6.3-51.1 13-76.6 13-117 0-212.4-80.4-212.4-179.2 0-98.7 95.4-179.2 212.4-179.2 115.1 0 211.9 81.9 211.9 179.2 0.4 49.1-30.4 99.7-87.2 142.1zM236.4 595.3c1.9-5.8 0-12-5.3-15.9-67.4-47.1-101.6-104.5-101.6-170.4 0-119 114.6-215.8 255.7-215.8 123.3 0 233.6 75.1 257.7 174.8C511.4 370.9 405 464.3 405 577.5c0 14.9 1.9 30.3 6.3 45.8h-1.9c-7.7 0.5-15.9 1-24.1 1-27.5 0-51.1-4.8-76.6-10.1l-15.4-2.9c-2.9-0.5-6.3 0-9.2 1L220 644.6l16.4-49.3z m0 0" p-id="4850" fill="#1afa29"></path><path d="M581.3 490.8c-14.9 0-29.9 14.9-29.9 29.9 0 14.9 14.9 29.9 29.9 29.9 22.6 0 36.6-15.4 36.6-29.9 0-14.5-14-29.9-36.6-29.9z m0 0M734.9 490.8c-14.9 0-29.4 14.9-29.4 29.9 0 14.9 14.4 29.9 29.4 29.9 22.6 0 36.6-15.4 36.6-29.9 0-14.5-14-29.9-36.6-29.9z m0 0" p-id="4851" fill="#1afa29"></path></svg>
       <span>绑定微信</span>
     </div>

     <div class="mine-list-line mine-list-line-1"  v-on:click="herf('/team')">
       <svg class="icon" aria-hidden="true">
         <use xlink:href="#icon-tuandui"></use>
       </svg>
       <span>团队列表</span>
     </div>
     <div class="mine-list-line" v-on:click="herf('/income')">
       <svg class="icon" aria-hidden="true">
         <use xlink:href="#icon-qianbao"></use>
       </svg>
       <span>钱包明细</span>
     </div>
     <div class="mine-list-line" v-on:click="herf('/income11111')">
       <svg class="icon" aria-hidden="true">
         <use xlink:href="#icon-wenti"></use>
       </svg>
       <span>常见问题</span>
     </div>
   </div>


  </div>
</template>

<script>
export default {
  name: 'Mine',
  data(){
    return {
      balance:0.00,
      allBalance:0.00,
      settledBalance:0.00,
      userName:"青柠",
      userId:'',
      headImgUrl:'http://img.lchuning.com/img/login.png',
      openid:'',
      userInfo:{}
    }
  },
  created(){
    console.log(this);
      console.log('mine');
  },
  mounted(){
    this.init();
  },
  methods:{
    init(){
      this.getUserInfo();
      this.userAccount();
    },
    bindwx(){
      let appid = "wxc403edc1fd99360f";
      let redirect_uri = 'https://chunfast.lchuning.com/app/wx/userBindWx';
      let state = this.userId;
      let url = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid='+appid+'&redirect_uri='+redirect_uri+'&response_type=code&scope=snsapi_userinfo&state='+state+'#wechat_redirect'
      location.href = url;

    },
    herf(name){
      this.$router.push(name)
    },
    loginAndRegister(){
      this.$toast('暂不支持上传头像!');
    },
    userAccount(){
      this.axios.post('/app/userAccount', {
          }).then((response) => {
            console.log(response);
            this.allBalance = response.allBalance
            this.balance = response.balance
            this.settledBalance = response.settledBalance
          }).catch(function (error) {
              console.log(error);
          });
    },
    getUserInfo(){
      this.axios.post('/app/userInfo', {
          }).then((response) => {
            console.log(response);
            this.userInfo = response;
            if(response.username.length>=4){
              this.userName = response.username;
            }else{
              if(response.username.length==2){
                this.userName = response.username.replace(/^(.).+$/,"$1*");
              }else{
                  this.userName = response.username.replace(/^(.).+(.)$/,"$1*$2");
              }
            }
            
            this.userId = response.userId;
            if(response.headImgUrl){
              this.headImgUrl = response.headImgUrl;
            }
            this.openid = response.openid;
              // this.slowList = response;
          }).catch(function (error) {
              console.log(error);
          });
    }
  }
}
</script>

<style scoped>
.icon{
  width: 30px;
  height: 30px;
  vertical-align: middle;
}
  .mine-info-view{
    height: 210px;
    text-align: center;
    background: #0ECB6E;
  }
  .info-head-portrait{
    background-color: #fff;
    width: 70px;
    height: 70px;
    margin-top: 30px;
    border-radius: 100%;
  }
  .info-user-name{
    color: #ffffff;
    margin: 5px 0 10px 0;
  }
  .info-user-level{
    border: 1px solid #2BD67E;
    border-radius: 20px;
    background: #3AD089;
    color: #ffffff;
    width: 20%;
    padding: 2px;
    margin: auto;
  }
  .user-income{
    border-radius: 5px;
    background: #ffffff;
    width: 90%;
    padding: 10px;
    margin: 15px auto 20px auto;
  }
  .user-income .user-income-price {
    color: #000000;
  }
  .user-income p{
    font-size: 14px;
    color: #A3A3A3;
    margin: 5px 0;
  }
  .mine-list{
    margin-top: 70px;
  }
  .mine-list .mine-list-line{
    padding: 10px;
    border-radius: 5px;
    width: 90%;
    margin: 3px auto;
    background: #ffffff;
  }
  .mine-list .mine-list-line span{
    margin: 0 10px;
    vertical-align: middle;
  }
.mine-list .mine-list-line-1{
    margin-top: 20px;
  }
</style>
